import aaveLiquidationEngine from "./projects/AaveLiquidationEngine";

export const projectsArr = [
  // new Project(
  //   "CDP with Yield-Farming Solana App",
  //   `Built and deployed a Solana-native collateralized-debt-position platform for yield-bearing assets on mainnet.
  //   Issuing debt (minting stablecoin) while earning yield (on 'yield farms', liquidity mining, etc)
  //   on what otherwise would-be idle assets - all while maintaining full self-custody of utilized assets.
  //   \n\nLead an Agile team of 10 developers, lead the design and technical implementation of the product
  //   line and was a major contributor on product decisions. Implemented Agile methodology and standards for collaborative
  //   development. Lead code reviews and managed external code audits.`,
  //   new Map([
  //     ["Blockchain", ["Solana", "Anchor.rs", "Rust"]],
  //     ["Backend", ["Typescript", "Node.js", "PostgreSQL", "Express.js"]],
  //     ["Frontend", ["Typescript", "React.js", "Redux.js"]],
  //   ]),
  //   "https://github.com/mrlucciola/cdp"
  // ),
  aaveLiquidationEngine,
  // new Project(
  //   "Portfolio Page",
  //   `\
  //     Rocco Lucciola's portfolio page
  //   `,
  //   new Map([["Frontend", ["React.js", "MobX", "Typescript"]]]),
  //   "https://github.com/mrlucciola/dev-site/",
  //   undefined,
  //   "https://mrlucciola.com/"
  // ),
  // new Project(
  //   "BDA",
  //   `
  //     Detects BPM and displays track information from user-added songs using a simple peak-detection algorithm
  //   `,
  //   new Map([
  //     ["Frontend", ["JavaScript", "Web Audio API", "React.js", "Redux.js"]],
  //   ]),
  //   "https://github.com/mrlucciola/bpm-detection-algorithm.git",
  //   "/images/bda-preview.png",
  //   "https://mrlucciola.github.io/bpm-detection-algorithm/"
  // ),
  // new Project(
  //   "Cofi",
  //   `\
  //     A web-based step-sequencer.\
  //     Simply point and click on the note you want to activate, and the time at which to activate.\
  //   `,
  //   new Map([["Frontend", ["React.js", "Web Audio API", "Redux.js"]]]),
  //   "https://github.com/mrlucciola/cofi.git",
  //   "/images/cofi-preview.png",
  //   "https://mrlucciola.github.io/cofi/"
  // ),
];
